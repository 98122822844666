@import "@styles/variables.scss";

$custom-medium-device: 900px;

.btnTab {
  border: none;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 8px 10px;
  border-radius: 12px;
  transition: $transition;
  cursor: pointer;
  justify-content: space-between;

  @media (max-width: $custom-medium-device) {
    flex-direction: column;
    align-items: flex-start;
  }

  .content {
    display: flex;
    gap: 10px;
    align-items: center;
    .bgImage {
      background-color: $white;
      padding: 12px;
      display: grid;
      place-items: center;

      img {
        object-fit: contain;
      }
    }

    .tabText {
      transition: 0.3s;
      text-align: left;
    }
  }

  i {
    font-size: 30px;
  }
}

.btnTabB2C {
  background-color: rgba($magneto-primary-btn, 0.2);

  .bgImage {
    border-radius: 50px;
  }

  &:disabled {
    background-color: $magneto-primary-btn;
    cursor: default;
    &:hover {
      box-shadow: 0 0 0px 0px $magneto-primary-btn;
    }
  }

  &:hover {
    box-shadow: 0 0 10px 0px $magneto-primary-btn;
  }

  .tabText {
    color: $gray-title;
    font-weight: 400;
    font-size: 15px;
    text-align: left;

    b,
    strong {
      color: $black;
    }
  }
}

.btnTabB2B {
  background-color: transparent;
  border: 1px solid #f0f1f3;

  @media (width <= $custom-medium-device) {
    flex-direction: row;
    align-items: center;
  }

  .bgImage {
    border-radius: 10px;
    @media (width <= $custom-medium-device) {
      padding: 5px !important;
      border-radius: 5px;
    }
    img {
      @media (width <= $custom-medium-device) {
        width: 40px;
        height: 30px;
      }
    }
  }

  .tabText {
    font-size: 18px;
    color: #0a0c0e;
    transition: 0.3s;
    @media (width <= $custom-medium-device) {
      font-size: 16px;
    }
  }

  &:disabled {
    background-color: #f0f1f3;
    cursor: default;
    &:hover {
      box-shadow: 0 0 0px 0px#F0F1F3;
    }
    .tabText {
      font-weight: bold;
    }
  }

  &:hover {
    box-shadow: 0 0 10px 0px#F0F1F3;
  }

  i {
    color: #0a0c0e;
    @media (width <= $custom-medium-device) {
      transform: rotate(90deg);
    }
  }
}
