.button-user-back {
  background-color: #090467;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  right: 20px;
  cursor: pointer;
  margin-top: -28px;
  transition: transform 0.3s ease;
  z-index: 2;
  .button-user-back__icon {
    color: white;
    width: 20px !important;
    height: 20px !important;
    min-height: 20px !important;
    min-width: 20px !important;
    min-height: auto;
  }
}

.button-user-back:hover {
  transform: scale(1.1);
}
