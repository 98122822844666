@import "@styles/variables.scss";

.type3 {
  background-color: $purple;
  color: $white;
  font-size: 20px;
  font-weight: 400;
  border-radius: 40px;
  padding: 0.7rem 1.2rem;
  width: max-content;
  white-space: break-spaces;
  display: flex;
  align-items: center;
  gap: 15px;

  &:hover {
    color: $white;
    background-color: $purple-hover;
  }

  img {
    width: 25px;
    height: 25px;
    object-fit: contain;
    filter: brightness(0) invert(1);
  }
}

.directionRight {
  flex-direction: row-reverse;
}
