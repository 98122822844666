@import "@styles/variables.scss";

.containerBannerBrowser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 1rem 0;
  position: relative;
  overflow: hidden;
  height: auto;

  &::before {
    content: "";
    background-color: #eff8ff;
    width: 100%;
    height: 56%;
    position: absolute;
    top: 0;

    @media (width <= 900px) {
      height: 50%;
    }

    @media (width <= $small-table) {
      height: 45%;
    }
  }

  @media (max-width: 1050px) {
    padding: 2rem;
  }

  @media (max-width: $mobile) {
    padding: 2rem 1rem;
    height: auto;
    gap: 2rem;
  }

  .bgImg {
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    z-index: 1;
    @media (max-width: $mobile) {
      height: 45%;
    }
  }

  .containerInfo {
    display: flex;
    z-index: 1;
    width: 70%;
    max-width: 1920px;

    @media (max-width: 1500px) {
      width: 85%;
    }

    @media (max-width: 1400px) {
      width: 92%;
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 3rem;
      color: $purple;
      width: 80%;

      @media (max-width: 1050px) {
        width: 100%;
        align-items: center;
      }

      & :is(.bannerTitle, .bannerSubTitle) {
        font-weight: 300;
        text-align: left;
        @media (max-width: $mobile) {
          text-align: center;
        }
      }

      p {
        line-height: normal;
      }

      .bannerTitle {
        @media (max-width: 1200px) {
          font-size: 280% !important;
        }

        @media (max-width: 1050px) {
          font-size: 220% !important;
        }
        @media (max-width: $mobile) {
          font-size: 180% !important;
          b,
          strong {
            display: block;
          }
        }
      }

      .bannerSubTitle {
        @media (max-width: 1200px) {
          font-size: 250% !important;
        }
        @media (max-width: 1050px) {
          font-size: 200% !important;
        }
        @media (max-width: $mobile) {
          font-size: 115% !important;
          font-weight: 400;
        }
      }
    }

    .containerButtonsBanner {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
      gap: 0.5rem;
      width: 100%;
      @media (max-width: $mobile) {
        grid-template-columns: 1fr 1fr;
      }
    }

    .bannerFigure {
      max-width: 360px;
      width: 100%;
      max-height: 436px;
      height: auto;
      object-fit: contain;
    }

    .popularSearches {
      color: $white;
      display: flex;
      gap: 10px;
      font-size: 18px;
      flex-wrap: wrap;
      justify-content: center;

      a {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 20px;
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 400;
        font-size: 15px;
        padding: 8px 15px;
        cursor: pointer;
        transition: $transition;

        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}

.snowContainer {
  background-color: $magneto-primary;

  &::before {
    display: none;
  }
}

.snow {
  background-color: $white;
  border-radius: 50px;
  position: absolute;
  z-index: 1;
  top: -100px;
  animation-name: fall;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  box-shadow: $box-shadow;
}

@keyframes fall {
  0% {
    top: -10%;
  }

  100% {
    top: 100%;
  }
}
