@import "@styles/variables.scss";

.infoText {
  color: $white !important;
  text-align: center;

  small {
    font-size: 16px;
  }
}

.logoMagnetoB2b {
  margin: 0 auto;
  display: block;
}

.containerButtons {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
}

.buttonBusinessCorporateButton {
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 20px;
  border-radius: 12px;
  flex: 1 1 250px;
  transition: $transition;

  &:hover {
    filter: $filter-brightness-light;

    i {
      transform: translateX(10px);
    }
  }

  p {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 80%;

    @media (width <= $large-table) {
      width: 80%;
    }

    @media (width <= $small-table) {
      width: 100%;
    }

    small {
      color: #63637e;
      font-size: 15px;
    }

    span {
      font-size: 18px;
      color: #14141c;
    }
  }

  i {
    color: #63637e;
    font-size: 20px;
    transition: $transition;
  }
}
