@import "@styles/variables.scss";

.arrowSliderHome::before {
  content: "" !important;
  background-image: url("https://static-next-stage.magneto365.com/wordpress/2023/12/28161303/arrow-blue-right.svg");
  background-position: center center;
  width: 25px;
  height: 25px;
  background-color: $white;
  padding: 5px;
  border-radius: 50px;
  display: block;
  background-size: 30%;
  background-repeat: no-repeat;
  transition: 0.2s;
  @media (max-width: 1000px) {
    display: none;
  }
}

.arrowSliderHome:first-child {
  &::before {
    transform: rotateY(180deg);
  }
}

.arrowSliderHome:hover::before {
  box-shadow: 0px 0px 10px #d0d0d0;
}

.dots {
  display: flex !important;
  list-style: none;
  gap: 10px;
  justify-content: center;
  padding: 1rem 0;
  li {
    button {
      width: 15px;
      height: 15px;
      border: none;
      color: black;
      background-color: black;
      border-radius: 20px;
      overflow: hidden;
      font-size: 12px;
      padding: 5px;
      opacity: 0.3;
      cursor: pointer;
    }
  }
}

.containerSlideVideo {
  background-color: $white;
  padding: 10px;
  border-radius: 15px;

  img {
    object-fit: contain;
  }
}
