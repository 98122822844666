@import "@styles/variables.scss";

.formSearchJob {
  display: flex;
  flex-direction: row;
  background-color: $white;
  padding: 12px 20px;
  border-radius: 50px;
  width: 100%;
  gap: 1rem;
  box-shadow: $box-shadow-light;

  @media (max-width: $mobile) {
    flex-direction: column;
    padding: 10px;
    border-radius: 20px;
  }

  button {
    border: none;
    cursor: pointer;
    outline: none;
  }

  .inputContainer {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    align-items: center;
    gap: 0.5rem;

    @media (max-width: $mobile) {
      background-color: $gray-8;
      padding: 0 10px;
      border-radius: 10px;
    }

    img {
      position: relative;
      opacity: 0.4;
      filter: grayscale(1);

      @media (max-width: $mobile) {
        width: 20px;
        height: 20px;
      }
    }

    div {
      display: block;
      width: 100%;
      position: relative;

      input {
        width: 100%;
        padding: 25px 10px 10px 10px;
        font-size: 120%;
        background-color: transparent;
        border: none;
        outline: none;
        color: $black;
        font-weight: 400;

        @media (max-width: $mobile) {
          font-size: 100%;
        }
      }

      label {
        position: absolute;
        cursor: text;
        z-index: 2;
        padding: 0;
        margin: 0;
        top: 16px;
        left: 10px;
        font-size: 120%;
        transition: $transition;
        color: $gray-title;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;

        @media (max-width: $mobile) {
          font-size: 90%;
          top: 17px;
        }
      }

      input:focus + label,
      input:valid + label {
        transform: translateY(-50%);
        font-size: 80%;
        font-weight: 400;

        @media (max-width: $mobile) {
          font-size: 70%;
          transform: translateY(-60%);
        }
      }
    }

    .deleteSearch {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: $gray-subtitle;
      transition: $transition;
      width: auto;

      button {
        background-color: $light-grey-hover;
        width: 25px;
        height: 25px;
        font-size: 15px;
        transition: $transition;
        border-radius: 50%;
      }
    }
  }

  .btnSearch {
    padding: 8px 30px;
    border-radius: 50px;
    color: $white;
    font-size: 120%;
    width: auto;
    height: auto;
    background-color: $purple;
    transition: $transition;

    &:hover {
      background-color: $purple-hover;
    }
  }
}
