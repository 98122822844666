@import "@styles/variables.scss";

.containerLoader {
  background-color: #0000001a;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0;
  display: grid;
  place-content: center;
  margin: 0 auto;
  transition: $transition;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  @media (max-width: $small-table) {
    opacity: 1;
  }

  .iconPlayer {
    width: 70px;
    height: 70px;
    @media (width <= 950px) {
      width: 50px;
      height: 50px;
    }
  }

  .loader {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: $green;
    box-shadow: $box-shadow;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
