@import "@styles/variables.scss";

$custom-medium-device: 900px;

.blockVerticalTabs {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .containerVerticalTabs {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1.2rem;

    @media (max-width: $custom-medium-device) {
      grid-template-columns: 1fr;
    }

    .buttons ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @media (max-width: $custom-medium-device) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        gap: 10px;
        overflow-x: auto;
        width: calc(95vw - 10px);
        white-space: nowrap;
        padding-bottom: 15px;
        scrollbar-width: thin;
        overflow-scrolling: touch;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
          height: 10px;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $white !important;
          border-radius: 10px;
          border: 2px solid #e3e7e9;
        }

        &::-webkit-scrollbar-track {
          background-color: #e3e7e9 !important;
          border-radius: 10px;
        }

        li {
          flex: 0 0 200px;
          box-sizing: border-box;
          white-space: break-spaces;
          display: flex;

          button span {
            white-space: break-spaces;
          }
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }
}

.styleBlockB2B {
  border: 1px solid #e3e7e9;
  border-radius: 12px;
  padding: 20px;

  .buttons ul {
    @media (width <= $custom-medium-device) {
      width: 80vw !important;
      margin: 0 auto;
      scrollbar-width: thin;
      overflow-scrolling: touch;
      -webkit-overflow-scrolling: touch;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        height: 10px;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: white !important;
        border-radius: 10px;
        border: 2px solid #e3e7e9;
      }

      &::-webkit-scrollbar-track {
        background-color: #e3e7e9 !important;
        border-radius: 10px;
      }
    }
  }

  .content {
    border-left: 1px solid #e3e7e9;
    padding: 20px;
    @media (width <= $custom-medium-device) {
      border-top: 1px solid #e3e7e9;
      border-left: 0;
    }

    p {
      text-align: left;
    }
  }
}
