@import "@styles/variables.scss";

.btnTabContent {
  background: $purple;
  color: $white;
  font-size: 17px;
  padding: 15px 30px;
  font-weight: bold;
  margin: 0 auto;
  flex-direction: row-reverse;

  img {
    transition: $transition;
  }

  &:hover {
    filter: $filter-brightness;
    color: $white;
    img {
      transform: translateX(5px);
    }
  }
}

.description {
  text-align: center;
  padding: 15px 0;
}

.smallText {
  color: #63637e;
  text-align: center;
  font-size: 13px;
}
