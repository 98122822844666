@import "@styles/variables.scss";
$custom-hover: scale(1.1);

.contentCompanies {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  padding: 0;

  li {
    border-radius: 10px;
    width: 180px;
    height: auto;
    padding: 10px;
    display: grid;
    place-items: center;
    transition: $transition;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      transition: $transition;

      &:hover {
        transform: $custom-hover;
      }
    }

    .logoCreateAccount {
      width: 100%;
      height: 100%;
      transition: $transition;
      cursor: pointer;
      border: none;
      background: transparent;

      &:hover {
        transform: $custom-hover;
      }
    }

    @media (max-width: 600px) {
      width: 30%;
    }
  }
}
