@import "@styles/variables.scss";

.card {
  a {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-decoration: none;
    color: $black;
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
  }

  p {
    font-size: 17px;
  }
}
