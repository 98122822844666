.bannersAdvertising {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  a {
    flex: 1 1 400px;
    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }
}
