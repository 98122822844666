@import "@styles/variables.scss";

.containerVideoYoutube {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  cursor: pointer;
  display: grid;
  place-items: center;
  border: 1px solid #f0f1f3;
  border-radius: 6px;

  img {
    width: 100%;
    height: auto;
    border-radius: 6px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}
