@import "@styles/variables.scss";

.buttonFilter {
  background-color: $gray-8;
  color: $white;
  display: flex;
  padding: 1rem 1rem;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  width: 100%;
  border-radius: 12px;
  cursor: pointer;
  position: relative;

  &:hover {
    filter: $filter-brightness-light;
    i {
      transform: translateX(10px);
    }
  }

  div.containerInfoBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    transition: $transition;

    & > div {
      width: 100%;
      .btnFilterTitle {
        color: $gray-title;
        font-size: 90%;
      }

      .btnFilterSubTitle {
        font-weight: 700;
        font-size: 100%;
        color: $purple;
      }
    }
  }

  i {
    color: $gray-title;
    font-size: 140%;
    transition: $transition;
  }
}
