@import "@styles/variables.scss";

.frequentLinks {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  h2 {
    text-align: left !important;
    font-weight: bold;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    gap: 15px;

    li a {
      background-color: $gray-8;
      border-radius: 20px;
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 400;
      font-size: 14px;
      padding: 8px 14px;
      color: #333;
      cursor: pointer;
      transition: $transition;

      i {
        transition: $transition;
      }

      &:hover {
        background-color: #cfcfde;

        i {
          transform: translateX(5px);
        }
      }
    }
  }

  button {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: $purple;
    font-size: 15px;
    padding: 10px;
    display: block;
    width: max-content;
    margin: 0 auto;
    cursor: pointer;
  }
}
