@import "@styles/variables.scss";

.referralBox {
  border-radius: 12px;
  width: 100%;

  &:hover {
    i {
      transform: translateX(10px);
    }
  }

  a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  img {
    transform: scale(1.5) translateY(-10px) translateX(10px);
  }

  .infoReferral {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 20px 5px 50px;

    div {
      color: $white;
      p {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 5px;
      }

      small {
        font-size: 18px;
        font-weight: 400;
      }
    }
    i {
      font-size: 30px;
      color: $white;
      transition: 0.3s ease-in-out;
    }
  }
}

.pymesBox {
  border-radius: 20px;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  .infoPymes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.2rem;
    width: 100%;

    div {
      color: $white;
      p {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 12px;
        line-height: normal;

        b {
          font-weight: 900;
        }
      }

      small {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  & > img {
    max-width: 461px;
    width: 50%;
    height: auto;
  }
}

@media (width <= $small-table) {
  .pymesBox {
    & > img {
      display: none;
    }
  }
}

@media (width <= $mobile) {
  .referralBox {
    padding: 15px 10px;
    border-radius: 0;
    width: calc(100% + 8.5%);

    a {
      gap: 10px;
    }

    img {
      transform: none;
      width: 110px;
      height: 110px;
    }

    .infoReferral {
      padding: 0;

      div {
        p {
          font-size: 20px;
        }

        small {
          font-size: 16px;
        }
      }

      i {
        display: none;
      }
    }
  }

  .pymesBox {
    padding: 40px 10px;
    border-radius: 0;
    width: calc(100% + 8.5%);
    flex-direction: column;
    justify-content: center;

    .infoPymes {
      div {
        p {
          font-size: 20px;
        }

        small {
          font-size: 16px;
        }
      }
    }
  }
}
