@import "@styles/variables.scss";

.generalLandingStyle {
  & :is(h1, h2) {
    color: $black;
    font-size: 200%;
    text-align: center;
    @media (max-width: $small-table) {
      font-size: 160%;
    }
  }

  h3 {
    color: $gray-subtitle;
    font-weight: 400;
    text-align: center;
    font-size: 150%;
    @media (max-width: $small-table) {
      font-size: 130%;
    }
  }

  .titleLogosCompanies {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .containerSmall {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    small {
      color: $gray-title;
      font-size: 13px;
      text-align: center;
    }
  }

  .containerSlider {
    max-width: 1920px;
    width: 70%;
    margin: 0 auto;
    padding: 4rem 0;

    @media (max-width: 1500px) {
      width: 85%;
      gap: 4rem;
    }

    @media (max-width: 1400px) {
      width: 92%;
    }

    @media (max-width: 600px) {
      width: 100%;
      gap: 4rem;
      padding: 1.5rem 0;
    }
  }

  .containerVideo {
    width: 80%;
    margin: 0 auto;
    display: grid;
    gap: 3rem;
    @media (max-width: $small-table) {
      width: 100%;
    }
  }
}

.sectionContainer {
  width: 100%;
  border-radius: 20px;
  padding: 2rem 2.5rem;

  @media (width <= $mobile) {
    padding: 2rem 1rem;
  }

  .btn {
    background-color: white;
    color: #000096;
    flex-direction: row-reverse;
  }

  &BgGray {
    background-color: $gray-8;
  }

  &Flex {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }

  &Block {
    display: block;
    @media (width <= $mobile) {
      width: 92%;
      margin: 0 auto;
    }
    h2 {
      margin-bottom: 2rem;
    }
  }
}
