@import "@styles/variables.scss";

.btn {
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;

  @media (max-width: $small) {
    font-size: 16px;
  }
}

.buttonsSocialMediaMedia {
  display: flex;
  gap: 15px;
  align-items: center;
  width: auto;
  flex-wrap: wrap;
  justify-content: center;

  p {
    color: #282835;
    font-weight: bold;
    text-align: center;
  }
}
