@import "@styles/variables.scss";

.containerBtnCreateAccount {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .btnCreateAccount {
    border-radius: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px 25px;
    font-size: 14px;
    transition: $transition;
    border: none;
    cursor: pointer;

    i {
      font-size: 20px;
      transition: $transition;
    }

    &:hover {
      i {
        transform: translateX(5px);
      }
    }
  }

  .iconRight {
    flex-direction: row-reverse;
  }

  .green {
    background: $btn-sign-up;
    color: $purple;
    &:hover {
      filter: $filter-brightness;
    }
  }

  .purple {
    background: $purple;
    color: $white;

    &:hover {
      background-color: $purple-hover;
    }

    img {
      filter: brightness(0) invert(1);
    }
  }

  .blue {
    background: $magneto-primary-btn;
    color: $purple;

    &:hover {
      filter: $filter-brightness;
    }

    i {
      color: $purple;
    }
  }
}
