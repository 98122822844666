@import "@styles/variables.scss";

.containerBlockCompany {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  align-items: center;

  .blockCompanyInfo {
    width: 70%;

    @media (max-width: $small-table) {
      width: 100%;
    }

    h1,
    h2,
    p {
      text-align: center;
    }
    h1,
    h2 {
      margin-bottom: 20px;
    }
  }
}
