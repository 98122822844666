@import "@styles/variables.scss";

.contentPost {
  display: grid;
  width: 100%;
}

.contentPost article {
  transition: 0.3s;
}

.typeCard {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 3rem;
}

.typeRow,
.typeBanner {
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (max-width: 768px) {
    .typeRow {
      grid-template-columns: 1fr;
      overflow: hidden;
    }

    .typeRow article a div p {
      display: none;
    }
  }
}
