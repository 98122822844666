@import "@styles/variables.scss";

.banner {
  position: relative;
  display: flex;
  gap: 15px;
  align-items: center;
  border-radius: 10px;
  background-color: $gray-8;
  box-shadow: $box-shadow;

  @media (width <= $small-table) {
    flex-direction: column-reverse;
  }

  @media (width <= $small-table) {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 4px;
      left: 0;
      right: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.9335084375547094) 40%,
        rgba(0, 0, 0, 0.8998949921765581) 48%,
        rgba(0, 0, 0, 0.6337885495995272) 73%,
        rgba(255, 255, 255, 0) 100%
      );
      opacity: 0.8;
      z-index: 1;
      border-radius: 10px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2%;
    font-weight: bold;
    width: 30%;

    @media (width <= $large-table) {
      width: 40%;
    }

    @media (width <= $small-table) {
      width: 100%;
      position: absolute;
      z-index: 2;
      padding: 4%;
      bottom: 15px;
    }

    h2 {
      font-size: 30px;
      font-weight: bold;
      color: $black;

      @media (width <= $small-table) {
        color: white;
      }
    }

    p {
      color: $black;
      font-weight: 400;
      @media (width <= $small-table) {
        color: white;
      }
    }

    a {
      font-size: 17px;
    }
  }

  img {
    width: 70%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    border-radius: 0 10px 10px 0;

    @media (width <= $large-table) {
      width: 60%;
    }

    @media (width <= $small-table) {
      width: 100%;
      height: 450px;
      border-radius: 10px;
    }
  }
}
