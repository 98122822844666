.spinLoad {
  animation: load 2s infinite;
  font-size: 25px;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
