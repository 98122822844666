@import "@styles/variables.scss";

.bodyRowPage {
  max-width: 1920px;
  width: 70%;
  margin: 0 auto;
  padding: 4rem 0;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 8rem;
  transition: 0.3s;
  @media (max-width: 1500px) {
    width: 85%;
    gap: 5rem;
  }

  @media (max-width: 1400px) {
    width: 92%;
    gap: 5rem;
  }

  @media (max-width: 1400px) {
    width: 92%;
    gap: 5rem;
  }

  @media (max-width: $mobile) {
    gap: 3rem;
  }
}
