@import "@styles/variables.scss";

.arrow {
  z-index: 2;

  &::before {
    color: $white;
    font-size: 30px;
  }
}

.arrowRight {
  right: 25px;
}

.arrowLeft {
  left: 25px;
}

@media (max-width: 768px) {
  .arrowLeft {
    left: 10px;
  }

  .arrowRight {
    right: 10px;
  }
}
