@import "@styles/variables.scss";

.PostRow {
  border-radius: 0px;
  padding-bottom: 20px;
  border-bottom: 2px solid #e9e9f6;

  &:last-child {
    border: none;
  }

  a {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    text-decoration: none;
    color: $black;
    align-items: center;
  }

  img {
    width: 20%;
    height: 130px;
    object-fit: cover;
    border-radius: 10px;
  }

  div {
    width: 80%;

    h2 {
      font-size: 20px;
      margin-bottom: 15px;
      text-align: left;
      color: #14141c;
      font-weight: 400;
    }

    .PostRowInfo {
      width: 100%;
      p {
        color: #63637e;
      }
    }
  }
}

@media (max-width: 768px) {
  .PostRow {
    a {
      flex-direction: column;
      gap: 2rem;

      img {
        width: 100%;
      }

      div {
        width: 100%;
        white-space: break-spaces;
      }
    }
  }
}
